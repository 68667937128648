import React from "react";
import Header from "../components/header";

import {Helmet} from "react-helmet";
import "../styles/index.css";
import {StaticImage} from "gatsby-plugin-image";

function Index() {
    return (
        <main>
            <Helmet>
                <title>IxDF Matching System</title>
            </Helmet>

            <Header/>

            <section class="bg-ixdf">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1">
                            <h1 class="text-light">IxDF Matching System</h1>

                            <p class="lead text-light">
                                Created initial concepts and design to increase learning and revenue by connecting a
                                Member with content, mentors, courses and master classes. </p>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2">

                            <StaticImage
                                src="../images/card-thumb-matching-system.png"
                                alt="Indiana University logo"
                                placeholder="blurred"
                                className="img-fluid"
                            />

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- content --> */}

            <div className="message">Case study snapshot. Full version available by request. </div>


            <section>
                <div class="container">
                    <div class="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <h2>Situation</h2>
                            <p className="">
                                Analytics showed members were following a single path through our sites, failing to
                                discover content, experiences, people related to their goals and history. This hurt
                                associative learning, learning opportunities and possible revenue.</p>

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <h2>Task</h2>
                            <p className="">
                                Concept and Design a matching system to recommend content, mentors, courses, master
                                classes to Members based on their goals, history and entity associations.
                            </p>

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <h2>Team/Stakeholders</h2>

                            <ul className="">
                                <li>
                                    Mehrdad, Back End Developer
                                </li>
                                <li>
                                    Alies, Back End Developer
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <h2>Actions</h2>

                            <ul className="">
                                <li>
                                    Research—Analytics
                                </li>
                                <li>
                                    Definition—ERD
                                </li>
                                <li>
                                    Design—Concept sketches, concept screens
                                </li>
                                <li>
                                    Documentation—Created detailed plan for implementing the system
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <h2>Result</h2>
                            <p className="">
                                MVP reviewed with key stakeholders for feasibility. Not yet added to the product roadmap.
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- end content --> */}
        </main>
    );
}

export default Index;
